// // import CardIcon from "../images/credit-card.sug";
// // import ProductImage from ".
// // /images /product - image.jpg";
// import PayPalCheckoutButton from "../PayPalCheckoutButton/PayPalCheckoutButton";
// import "../Checkout/Checkout.scss"
// import { useLocation } from 'react-router-dom';
// import { formatPrice } from "../../utils/helpers";



// const Checkout = () => {

    
//     const location = useLocation();
//     const { state } = location.state;
//     const { cartProducts } = state;



//     return (
//         <div className="checkout">
//             <h1>PayPal Checkout</h1>
//             <p className="checkout-title">Design + Code React Hooks Course</p>
//             <p className="checkout-description">
//                 Learn how to build a website with React Hooks
//             </p>
   
//                 {
//                     Object.values(cartProducts).map((value, index) => {
//                         return (
//                             <div key={index}>
//                                 <><h1 className="checkout-price"> {formatPrice(value.price)}
//                                 </h1><img className="checkout-product-image" src={"ProductImage"} alt="product" /></>
//                             </div>
//                         );
//                     })

//                 }

//             <button className="checkout-button">
//                 <div className="grey-circle">
//                     <div className="purple-circle">
//                         <img className="icon" src={"CardIcon"} alt="credit-card-icon" />
//                     </div>
//                 </div>
//                 <div className="text-container">
//                     <p className="text">Buy</p>
//                 </div>
//             </button>
//             <div className="separator">

//             </div>
//             <div className="paypal">
//                 <p className="checkout-title">OR, PAY WITH PAYPAL</p>
//                 <div className="paypal-button-container">
//                     <PayPalCheckoutButton product={product} />
//                 </div>
//             </div>

//         </div>
//     );

// }

// export default Checkout;