import { PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";
import {getDelivericharge } from '../../utils/helpers';
import { getTotalAmount } from '../../utils/helpers'

const PayPalCheckoutButton = (props) => {

    const { product } = props;
    const [paidFor, setPaidFor] = useState(false);
    const [error, setError] = useState(null);
    const handleApprove = (orderId) => {

        //call back-end function to fullfill order

        setPaidFor(true);
        //refresh user account
        //


        //setError()

    };

    if (paidFor) {
        //display succes message, modal or redirect to success page
        alert("Thank you for your purchase!")
    }

    if (error) {
        //display succes message, modal or redirect to success page
        alert(error)
    }


    let actualAmount = product.price

    const createOrderWithRetry = (data, actions, errorThreshold, actualAmount) => {
        const deliveryCharge = getDelivericharge();
        const expectedAmount = getTotalAmount(deliveryCharge);
      
        console.log(`actualAmount: ${actualAmount} expectedAmount: ${expectedAmount}`);
      
        if (actualAmount === expectedAmount) {
          return actions.order.create({
            purchase_units: [
              {
                description: product.title,
                amount: {
                  value: actualAmount,
                },
              },
            ],
          });
        } else if (errorThreshold === 0) {
          console.log("Amount not as expected!");
          setError("Amount not as expected!");
          return Promise.reject("Amount not as expected!");
        } else {
          console.log(`Error threshold: ${errorThreshold}`);
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              if (errorThreshold === 1) {
                console.log("Setting actualAmount to expectedAmount after third attempt");
                actualAmount = expectedAmount;
              }
      
              resolve(createOrderWithRetry(data, actions, errorThreshold - 1, actualAmount));
            }, 1000);
          });
        }
      };



    return (

        <PayPalButtons

            style={{
                color: "gold",
                layout: "horizontal",
                height: 48,
                tagline: false,
                shape: "pill",
                disableMaxWidth:true,

            }}

            onClick={(data, actions) => {
                //validate on button click
                const hasAlreadyBoughtProduct = false;

                if (hasAlreadyBoughtProduct) {
                    setError("You already bought this item. Go to your account to view your past purchases!");
                    return actions.reject();

                } else {
                    return actions.resolve();
                }
            }}

            createOrder={(data, actions) => createOrderWithRetry(data, actions, 3)}
    
            onApprove={async (data, actions) => {
                const order = await actions.order.capture();
                console.log("order: ", order);

                handleApprove(data.orderID);

            }}

            onCancel={() => {
                //checkoutpage
            }}

            onError={(err) => {
                setError(err);
                console.error("Paypal Checkout went wrong onError", err)
            }}
        />

    );
};
export default PayPalCheckoutButton;
