import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from '../../components/Slider/Slider';
import Category from '../../components/Category/Category';
import ProductList from '../../components/ProductList/ProductList';
import SingleCategory from '../../components/SingleCategory/SingleCategory';
import { fetchProducts } from '../../store/productSlice';
import { fetchCategories, fetchProductsByCategory } from '../../store/categorySlice';
import './HomePage.scss';
import { sliderImages } from '../../utils/images';
import { Button } from '../../components/Button/Button';
import ProgressiveImage from "react-progressive-graceful-image";



const HomePage = () => {
  const dispatch = useDispatch();
  const { data: categories, status: categoryStatus } = useSelector((state) => state.category);
  const { data: products, status: productStatus } = useSelector((state) => state.product);
  const { catProductAll: productsByCategory, catProductAllStatus } = useSelector((state) => state.category);

useEffect(() => {
  const fetchData = async () => {
    try {
      await dispatch(fetchProducts());
      await dispatch(fetchCategories());
      await dispatch(fetchProductsByCategory(1, 'all'));
      await dispatch(fetchProductsByCategory(2, 'all'));
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle errors, e.g., display an error message to the user
    }
  };

  fetchData();
}, [dispatch]);

const [showHomePage, setShowHomePage] = useState(false);
   
  return (
    <div>
      {showHomePage ? (
        // Conditionally render the home page
        <div className="home-page">
          <Slider />
          <Category categories={categories} status={categoryStatus} />
          <ProductList products={products} status={productStatus} />
          <section>
            {productsByCategory[0] && (
              <SingleCategory products={productsByCategory[0]} status={catProductAllStatus} />
            )}
          </section>
          <section>
            {productsByCategory[1] && (
              <SingleCategory products={productsByCategory[1]} status={catProductAllStatus} />
            )}
          </section>
        </div>
      ) : (
        <div className="hero-container">
          <ProgressiveImage
            src={sliderImages[3]}
            placeholder={sliderImages[4]}>
            {(src, loading) => (
              <img
                className={`centered-image ${loading ? " loading" : " loaded"}`}
                src={src}
                alt="motorcycle"
              />
            )}
          </ProgressiveImage>
          <h1>NEW RELEASES</h1>
          <p>New newness. Did we mention they’re new?</p>
          <div className="hero-btns">
            <Button
              className='btn'
              buttonStyle='btn--outline'
              buttonSize='btn--large'
              onClick={() => setShowHomePage(true)}>Shop Now</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
