export const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: "USD"
    }).format(price / 100);
}

export const getDelivericharge = () =>{
    const savedDeliveryCharge = localStorage.getItem('deliveryCharge');
    const parsedDeliveryCharge = JSON.parse(savedDeliveryCharge);
    return parsedDeliveryCharge;
}
export const formatPriceWithoutCurrency = (price) => {
    return price / 100;
}


export const getTotalAmount = (deliveryCharge) => {
    let totalPriceSum = 0;
    const savedCart = localStorage.getItem('cart');
    const parsedCart = JSON.parse(savedCart);


    if (parsedCart) {
        totalPriceSum = parsedCart.reduce((accumulator, currentItem) => {
            return accumulator + currentItem.totalPrice;
        }, 0);

        console.log('Sum of totalPrice:' + formatPriceWithoutCurrency(totalPriceSum+deliveryCharge));

    }
    return formatPriceWithoutCurrency(totalPriceSum + deliveryCharge) || "No total price sum";

}

