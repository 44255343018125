import React, { useState, useEffect } from 'react';
import "./Navbar.scss";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories } from '../../store/categorySlice';
import { getCartTotal } from '../../store/cartSlice';

const Navbar = () => {
  const dispatch = useDispatch();
  const { data: categories } = useSelector((state) => state.category);
  const { totalItems } = useSelector((state => state.cart));

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(getCartTotal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // const handleLinkClick = () => {
  //   Navigate("/")
  // };
  return (
    <nav className="navbar">
      <div className='navbar-content'>
        <div className="container">
          <div className="navbar-top flex flex-between">
          {/* onClick={handleLinkClick} goes tot the below Link */}
            <Link to="/" className="navbar-brand" >
              <span className="text-regal-blue">Apex</span><span className='text-gold'>Dev</span><span className="text-regal-blue">Pro</span>
              <i className="fab fa-think-peaks"></i>
            </Link>

            <form className="navbar-search flex">
              <input type="text" placeholder='Search here ...' />
              <button type="submit" className="navbar-search-btn">
                <i className="fas fa-search"></i>
              </button>
            </form>

            <div className="navbar-btns">
              <Link to="/cart" className="add-to-cart-btn flex">
                <span className="btn-ico">
                  <i className="fas fa-shopping-cart"></i>
                </span>
                <div className='btn-txt fw-5'>Cart
                  <span className='cart-count-value'>{totalItems}</span>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className='navbar-bottom bg-regal-blue'>
          <div className='container flex flex-between'>
            <ul className={`nav-links flex ${isSidebarOpen ? 'show-nav-links' : ""}`}>
              <button type="button" className='navbar-hide-btn text-white' onClick={() => setIsSidebarOpen(false)}>
                <i className='fas fa-times'></i>
              </button>
              {
                categories.map(category => (
                  <li key={category.id}><Link to={`/category/${category.id}`} className="nav-link text-white" onClick={() => setIsSidebarOpen(false)}>{category.name}</Link></li>
                ))
              }

              {/* <li><Link to={""} className="nav-link text-white" onClick={() => setIsSidebarOpen(false)}>Registration</Link></li> */}

              {/* 
                <Link
                  to='/sign-up'
                  className="nav-link text-white"
                  // onClick={setIsSidebarOpen(false)}
                >
                  Sign Up
                </Link> */}
              {/* </li> */}

            </ul>
            <div className='menu-icon navbar-show-btn text-gold' onClick={() => setIsSidebarOpen(true)}>
              <i className="fas fa-bars"></i>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;