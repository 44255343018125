import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// pages
import { Home, Category, Cart } from "./pages/index";
import Navbar from './components/Navbar/Navbar';
import Footer from "./components/Footer/Footer";
import Checkout from './components/Checkout/Checkout';
import { Provider } from 'react-redux';
import store from "./store/store";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
const config = require('./env');
//clientId for paypal pay
const REACT_APP_PAYPAL_CLIENT_ID = config.REACT_APP_PAYPAL_CLIENT_ID;

function App() {
  return (
    <PayPalScriptProvider options={{ "client-id": REACT_APP_PAYPAL_CLIENT_ID }}>

      <div className="App">
        <Provider store={store}>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/category/:id" element={<Category />} />
              <Route path="/cart" element={<Cart />} />
              {/* <Route path="/checkout" element={<Checkout />} /> */}
            </Routes>
            <Footer />
          </BrowserRouter>
        </Provider>
      </div>
    </PayPalScriptProvider >
  );
}

export default App;
